import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/styles';

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.light};
  height: 80vh;
  padding: 32px;

  @media (max-width: ${breakpoints.l}px){
    padding: 24px;
  }
`;

export const Container = styled.div`
  max-width: 800px;
  color: ${({ theme }) => theme.textDark};
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
`;