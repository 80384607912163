import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Section, Container, Action } from './styles';

import Button from '../../UI/Button';

const NotFound = ({ title, text, buttonText, buttonLink }) => {
  return (
    <Section>
      <Container>
        {RichText.render(title.raw)}
        {RichText.render(text.raw)}
        <Action>
          <Button 
            text={RichText.asText(buttonText.raw)} 
            link={buttonLink} 
          />
        </Action>
      </Container>
    </Section>
  );
};

export default NotFound;