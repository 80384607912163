import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import NotFound from '../components/page/NotFound';

const NotFoundPage = ({ data }) => {
  const page = data.prismicNotFound.data;
  return (
    <Layout>
      <SEO 
        title={page.meta_title}
        description={page.meta_description}
        type='article'
      />
      <NotFound 
        title={page.title}
        text={page.description}
        buttonText={page.button_text}
        buttonLink="/"
      />
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    prismicNotFound {
      data {
        button_text {
          raw
        }
        description {
          raw
        }
        meta_title
        meta_description
        title {
          raw
        }
      }
    }
  }
`;
